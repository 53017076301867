import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { Button } from './Button';
import { Container } from './Container';
import { Logo } from './Logo';
import { NavLink } from './NavLink';
function MobileNavLink({ href, children, }) {
    return (_jsx(Popover.Button, Object.assign({ className: "block w-full p-2" }, { children: children })));
}
function MobileNavIcon({ open }) {
    return (_jsxs("svg", Object.assign({ "aria-hidden": "true", className: "h-3.5 w-3.5 overflow-visible stroke-slate-700", fill: "none", strokeWidth: 2, strokeLinecap: "round" }, { children: [_jsx("path", { d: "M0 1H14M0 7H14M0 13H14", className: clsx('origin-center transition', open && 'scale-90 opacity-0') }), _jsx("path", { d: "M2 2L12 12M12 2L2 12", className: clsx('origin-center transition', !open && 'scale-90 opacity-0') })] })));
}
function MobileNavigation() {
    return (_jsxs(Popover, { children: [_jsx(Popover.Button, Object.assign({ className: "relative z-10 flex h-8 w-8 items-center justify-center ui-not-focus-visible:outline-none", "aria-label": "Toggle Navigation" }, { children: ({ open }) => _jsx(MobileNavIcon, { open: open }) })), _jsxs(Transition.Root, { children: [_jsx(Transition.Child, Object.assign({ as: Fragment, enter: "duration-150 ease-out", enterFrom: "opacity-0", enterTo: "opacity-100", leave: "duration-150 ease-in", leaveFrom: "opacity-100", leaveTo: "opacity-0" }, { children: _jsx(Popover.Overlay, { className: "fixed inset-0 bg-slate-300/50" }) })), _jsx(Transition.Child, Object.assign({ as: Fragment, enter: "duration-150 ease-out", enterFrom: "opacity-0 scale-95", enterTo: "opacity-100 scale-100", leave: "duration-100 ease-in", leaveFrom: "opacity-100 scale-100", leaveTo: "opacity-0 scale-95" }, { children: _jsxs(Popover.Panel, Object.assign({ as: "div", className: "absolute inset-x-0 top-full mt-4 flex origin-top flex-col rounded-2xl bg-white p-4 text-lg tracking-tight text-slate-900 shadow-xl ring-1 ring-slate-900/5" }, { children: [_jsx(MobileNavLink, Object.assign({ href: "#features" }, { children: "Features" })), _jsx(MobileNavLink, Object.assign({ href: "#testimonials" }, { children: "Testimonials" })), _jsx(MobileNavLink, Object.assign({ href: "#pricing" }, { children: "Pricing" })), _jsx("hr", { className: "m-2 border-slate-300/40" }), _jsx(MobileNavLink, Object.assign({ href: "/login" }, { children: "Sign in" }))] })) }))] })] }));
}
export function Header() {
    return (_jsx("header", Object.assign({ className: "py-10" }, { children: _jsx(Container, { children: _jsxs("nav", Object.assign({ className: "relative z-50 flex justify-between" }, { children: [_jsxs("div", Object.assign({ className: "flex items-center md:gap-x-12" }, { children: [_jsxs("a", Object.assign({ href: "/", "aria-label": "Home", className: 'flex items-center gap-2' }, { children: [_jsx(Logo, { className: "h-10 w-auto" }), _jsx("span", Object.assign({ className: 'text-blue-600 text-lg font-medium' }, { children: "\u6781\u8BD1\u5B66\u672F" }))] })), _jsxs("div", Object.assign({ className: "hidden md:flex md:gap-x-6" }, { children: [_jsx(NavLink, Object.assign({ href: "#features" }, { children: "\u529F\u80FD\u4E0E\u4ECB\u7ECD" })), _jsx(NavLink, Object.assign({ href: "#testimonials" }, { children: "\u4EA7\u54C1\u8BC4\u4EF7" })), _jsx(NavLink, Object.assign({ href: "#pricing" }, { children: "\u5B9A\u4EF7" }))] }))] })), _jsxs("div", Object.assign({ className: "flex items-center gap-x-5 md:gap-x-8" }, { children: [_jsx(Button, Object.assign({ color: "blue" }, { children: _jsx("a", Object.assign({ href: "/worktop.html", target: "_blank" }, { children: _jsx("span", { children: "\u8FDB\u5165\u5DE5\u4F5C\u53F0" }) })) })), _jsx("div", Object.assign({ className: "-mr-1 md:hidden" }, { children: _jsx(MobileNavigation, {}) }))] }))] })) }) })));
}
