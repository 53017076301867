import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CallToAction } from './components/CallToAction';
import { Faqs } from './components/Faqs';
import { Footer } from './components/Footer';
import { Header } from './components/Header';
import { Hero } from './components/Hero';
import { Pricing } from './components/Pricing';
import { PrimaryFeatures } from './components/PrimaryFeatures';
import { SecondaryFeatures } from './components/SecondaryFeatures';
import { Reviews } from './components/Reviews';
export default function Popup() {
    return (_jsxs("div", Object.assign({ style: { maxWidth: '100%' } }, { children: [_jsx(Header, {}), _jsxs("main", { children: [_jsx(Hero, {}), _jsx(PrimaryFeatures, {}), _jsx(SecondaryFeatures, {}), _jsx(CallToAction, {}), _jsx(Reviews, {}), _jsx(Pricing, {}), _jsx(Faqs, {})] }), _jsx(Footer, {})] })));
}
