import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useState, useEffect } from 'react';
const GlobalSettingsContext = createContext(undefined);
export const useGlobalSettings = () => {
    const context = useContext(GlobalSettingsContext);
    if (context === undefined) {
        throw new Error('useGlobalSettings must be used within a GlobalSettingsProvider');
    }
    return context;
};
export const GlobalSettingsProvider = ({ children }) => {
    const storge_language = window.localStorage.getItem('language');
    const storge_appearance = window.localStorage.getItem('appearance');
    const storge_timeZone = window.localStorage.getItem('timeZone');
    const storge_autoTranstale = window.localStorage.getItem('autoTranslate');
    const storge_autoHandlePdf = window.localStorage.getItem('autoHandlePdf');
    const storge_autoPreview = window.localStorage.getItem('autoPreview');
    const storge_autoDownload = window.localStorage.getItem('autoDownload');
    const storge_transLang = window.localStorage.getItem('transLang');
    const storge_isWebDav = window.localStorage.getItem('isWebDav');
    const storage_listShowType = window.localStorage.getItem('listShowType');
    const storage_vectorId = window.localStorage.getItem('vectorId');
    const storage_articleScale = window.localStorage.getItem('articleScale');
    const [language, setLanguage] = useState(storge_language == null ? 0 : parseInt(storge_language)); //0为中文，1为English
    const [appearance, setAppearance] = useState(storge_appearance == null ? 1 : parseInt(storge_appearance)); //0为跟随系统，1为亮，2为暗
    const [timeZone, setTimeZone] = useState(storge_timeZone == null ? 8 : parseInt(storge_timeZone)); //区时
    const [autoHandlePdf, setAutoHandlePdf] = useState(storge_autoHandlePdf == null ? true : storge_autoHandlePdf === "true"); // 是否自动处理PDF，而不需要额外确认
    const [autoTranslate, setAutoTranslate] = useState(storge_autoTranstale == null ? false : storge_autoTranstale === "true");
    const [autoPreview, setAutoPreview] = useState(storge_autoPreview == null ? true : storge_autoPreview === "true"); //false为不自动，true为自动打开预览
    const [autoDownload, setAutoDownload] = useState(storge_autoDownload == null ? 0 : parseInt(storge_autoDownload)); //0为不自动下载，1为自动下载PDF，2为自动下载word，3为自动下载latex，4为自动下载markdown
    const [transLang, setTransLang] = useState(storge_transLang == null ? "zh-CN" : storge_transLang);
    const [isWebDav, setIsWebDav] = useState(storge_isWebDav == null ? false : storge_isWebDav === "true"); // webdav功能是否开启
    const [listShowType, setListShowType] = useState(storage_listShowType == null ? 0 : parseInt(storage_listShowType)); // 标识记录列表的样式，0表示详情，1表示简约，默认为0
    const [vectorId, setVectorId] = useState(storage_vectorId == null ? 0 : parseInt(storage_vectorId)); // 用户历史选中的矢量库id
    const [articleScale, setArticleScale] = useState(storage_articleScale == null ? 100 : parseInt(storage_articleScale)); // 用户遍及文章时的页面缩放尺寸
    const [customPageRefresh, setCustomPageRefresh] = useState(true); // 设置界面是否需要刷新
    const [isDarkMode, setIsDarkMode] = useState(false); // 页面是否处于暗黑模式
    useEffect(() => {
        localStorage.setItem('language', language.toString());
    }, [language]);
    useEffect(() => {
        localStorage.setItem('appearance', appearance.toString());
    }, [appearance]);
    useEffect(() => {
        localStorage.setItem('timeZone', timeZone.toString());
    }, [timeZone]);
    useEffect(() => {
        localStorage.setItem('autoTranslate', autoTranslate.toString());
    }, [autoTranslate]);
    useEffect(() => {
        localStorage.setItem('autoHandlePdf', autoHandlePdf.toString());
    }, [autoHandlePdf]);
    useEffect(() => {
        localStorage.setItem('autoPreview', autoPreview.toString());
    }, [autoPreview]);
    useEffect(() => {
        localStorage.setItem('autoDownload', autoDownload.toString());
    }, [autoDownload]);
    useEffect(() => {
        localStorage.setItem('transLang', transLang);
    }, [transLang]);
    useEffect(() => {
        localStorage.setItem('isWebDav', isWebDav.toString());
    }, [isWebDav]);
    useEffect(() => {
        localStorage.setItem('listShowType', listShowType.toString());
    }, [listShowType]);
    useEffect(() => {
        localStorage.setItem('vectorId', vectorId.toString());
    }, [vectorId]);
    useEffect(() => {
        localStorage.setItem('articleScale', articleScale.toString());
    }, [articleScale]);
    useEffect(() => {
        const handleDarkModeChange = (e) => {
            // 当 appearance 为 0 时，根据系统偏好设置样式
            if (appearance === 0) {
                setIsDarkMode(e.matches);
            }
        };
        const mediaQueryList = window.matchMedia('(prefers-color-scheme: dark)');
        mediaQueryList.addEventListener('change', handleDarkModeChange);
        // 根据 appearance 强制设置样式
        if (appearance === 1) {
            setIsDarkMode(false); // 亮模式
        }
        else if (appearance === 2) {
            setIsDarkMode(true); // 暗模式
        }
        else {
            // 跟随系统偏好
            setIsDarkMode(mediaQueryList.matches);
        }
        return () => {
            mediaQueryList.removeEventListener('change', handleDarkModeChange);
        };
    }, [appearance]);
    const value = {
        language,
        setLanguage,
        appearance,
        setAppearance,
        timeZone,
        setTimeZone,
        autoTranslate,
        setAutoTranslate,
        autoHandlePdf,
        setAutoHandlePdf,
        autoPreview,
        setAutoPreview,
        autoDownload,
        setAutoDownload,
        transLang,
        setTransLang,
        isWebDav,
        setIsWebDav,
        listShowType,
        setListShowType,
        vectorId,
        setVectorId,
        articleScale,
        setArticleScale,
        customPageRefresh,
        setCustomPageRefresh,
        isDarkMode,
    };
    return (_jsx(GlobalSettingsContext.Provider, Object.assign({ value: value }, { children: children })));
};
