import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import ReactDOM from 'react-dom/client';
import Popup from './Popup';
import { GlobalSettingsProvider } from '../../GlobalSettingsContext';
import '../../styles/global.css';
import { DBConfig } from '../../config';
import { initDB } from 'react-indexed-db-hook';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import '../../styles/tailwind.css';
initDB(DBConfig);
const root = document.createElement("div");
// root.className = "container"
document.body.appendChild(root);
const rootDiv = ReactDOM.createRoot(root);
// const rootDiv = ReactDOM.createRoot(document.body);
rootDiv.render(_jsx(HelmetProvider, { children: _jsx(GlobalSettingsProvider, { children: _jsxs(React.StrictMode, { children: [_jsxs(Helmet, { children: [_jsx("title", { children: "\u6781\u8BD1 - \u60A8\u7684\u4E13\u4E1A\u6587\u732E\u52A9\u624B" }), _jsx("link", { rel: "shortcut icon", type: "images/x-icon", href: "/favicon.ico" }), _jsx("meta", { name: "description", content: "\u6781\u8BD1 - \u60A8\u7684\u4E13\u4E1A\u6587\u732E\u52A9\u624B" })] }), _jsx(Popup, {})] }) }) }));
