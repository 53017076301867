var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import { Button } from './Button';
import { Container } from './Container';
function SwirlyDoodle(props) {
    return (_jsx("svg", Object.assign({ "aria-hidden": "true", viewBox: "0 0 281 40", preserveAspectRatio: "none" }, props, { children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M240.172 22.994c-8.007 1.246-15.477 2.23-31.26 4.114-18.506 2.21-26.323 2.977-34.487 3.386-2.971.149-3.727.324-6.566 1.523-15.124 6.388-43.775 9.404-69.425 7.31-26.207-2.14-50.986-7.103-78-15.624C10.912 20.7.988 16.143.734 14.657c-.066-.381.043-.344 1.324.456 10.423 6.506 49.649 16.322 77.8 19.468 23.708 2.65 38.249 2.95 55.821 1.156 9.407-.962 24.451-3.773 25.101-4.692.074-.104.053-.155-.058-.135-1.062.195-13.863-.271-18.848-.687-16.681-1.389-28.722-4.345-38.142-9.364-15.294-8.15-7.298-19.232 14.802-20.514 16.095-.934 32.793 1.517 47.423 6.96 13.524 5.033 17.942 12.326 11.463 18.922l-.859.874.697-.006c2.681-.026 15.304-1.302 29.208-2.953 25.845-3.07 35.659-4.519 54.027-7.978 9.863-1.858 11.021-2.048 13.055-2.145a61.901 61.901 0 0 0 4.506-.417c1.891-.259 2.151-.267 1.543-.047-.402.145-2.33.913-4.285 1.707-4.635 1.882-5.202 2.07-8.736 2.903-3.414.805-19.773 3.797-26.404 4.829Zm40.321-9.93c.1-.066.231-.085.29-.041.059.043-.024.096-.183.119-.177.024-.219-.007-.107-.079ZM172.299 26.22c9.364-6.058 5.161-12.039-12.304-17.51-11.656-3.653-23.145-5.47-35.243-5.576-22.552-.198-33.577 7.462-21.321 14.814 12.012 7.205 32.994 10.557 61.531 9.831 4.563-.116 5.372-.288 7.337-1.559Z" }) })));
}
function CheckIcon(_a) {
    var { className } = _a, props = __rest(_a, ["className"]);
    return (_jsxs("svg", Object.assign({ "aria-hidden": "true", className: clsx('h-6 w-6 flex-none fill-current stroke-current', className) }, props, { children: [_jsx("path", { d: "M9.307 12.248a.75.75 0 1 0-1.114 1.004l1.114-1.004ZM11 15.25l-.557.502a.75.75 0 0 0 1.15-.043L11 15.25Zm4.844-5.041a.75.75 0 0 0-1.188-.918l1.188.918Zm-7.651 3.043 2.25 2.5 1.114-1.004-2.25-2.5-1.114 1.004Zm3.4 2.457 4.25-5.5-1.187-.918-4.25 5.5 1.188.918Z", strokeWidth: 0 }), _jsx("circle", { cx: 12, cy: 12, r: 8.25, fill: "none", strokeWidth: 1.5, strokeLinecap: "round", strokeLinejoin: "round" })] })));
}
function Plan({ name, price, description, href, features, featured = false, }) {
    return (_jsxs("section", Object.assign({ className: clsx('flex flex-col rounded-3xl px-6 sm:px-8', featured ? 'order-first bg-blue-600 py-8 lg:order-none' : 'lg:py-8') }, { children: [_jsx("h3", Object.assign({ className: "mt-5 font-display text-lg text-white" }, { children: name })), _jsx("p", Object.assign({ className: clsx('mt-2 text-base', featured ? 'text-white' : 'text-slate-400') }, { children: description })), _jsx("p", Object.assign({ className: "order-first font-display text-5xl font-light tracking-tight text-white" }, { children: price })), _jsx("ul", Object.assign({ role: "list", className: clsx('order-last mt-10 flex flex-col gap-y-3 text-sm', featured ? 'text-white' : 'text-slate-200') }, { children: features.map((feature) => (_jsxs("li", Object.assign({ className: "flex" }, { children: [_jsx(CheckIcon, { className: featured ? 'text-white' : 'text-slate-400' }), _jsx("span", Object.assign({ className: "ml-4" }, { children: feature }))] }), feature))) })), _jsx(Button, Object.assign({ variant: featured ? 'solid' : 'outline', color: "white", className: "mt-8", "aria-label": `Get started with the ${name} plan for ${price}` }, { children: _jsx("a", Object.assign({ href: href, target: "_blank" }, { children: "\u4E86\u89E3\u66F4\u591A" })) }))] })));
}
export function Pricing() {
    return (_jsx("section", Object.assign({ id: "pricing", "aria-label": "Pricing", className: "bg-slate-900 py-20 sm:py-32" }, { children: _jsxs(Container, { children: [_jsxs("div", Object.assign({ className: "md:text-center" }, { children: [_jsxs("h2", Object.assign({ className: "font-display text-3xl tracking-tight text-white sm:text-4xl" }, { children: [_jsxs("span", Object.assign({ className: "relative whitespace-nowrap" }, { children: [_jsx(SwirlyDoodle, { className: "absolute left-0 top-1/2 h-[1em] w-full fill-blue-400" }), _jsx("span", Object.assign({ className: "relative" }, { children: "\u5B9A\u4EF7\u7B80\u5355\uFF0C" }))] })), ' ', "\u4E00\u6B65\u641E\u5B9A"] })), _jsx("p", Object.assign({ className: "mt-4 text-lg text-slate-400" }, { children: "\u6211\u4EEC\u4E3A\u6240\u6709\u7528\u6237\u63D0\u4F9B\u6838\u5FC3\u529F\u80FD\u7684\u514D\u8D39\u4F7F\u7528\u3002\u60A8\u4E5F\u53EF\u4EE5\u901A\u8FC7\u8BA2\u9605\u6765\u83B7\u53D6\u5230\u66F4\u591A\u9AD8\u7EA7\u529F\u80FD\uFF0C\u5305\u62EC\u4E13\u5BB6\u7EA7\u5206\u6790\u5DE5\u5177\u3001\u66F4\u9AD8\u4EA7\u54C1\u989D\u5EA6\u548C\u4F18\u5148\u5BA2\u6237\u652F\u6301\uFF0C\u8BA9\u60A8\u7684\u7814\u7A76\u66F4\u4E0A\u4E00\u5C42\u697C\u3002" }))] })), _jsxs("div", Object.assign({ className: "-mx-4 mt-16 grid max-w-2xl grid-cols-1 gap-y-10 sm:mx-auto lg:-mx-8 lg:max-w-none lg:grid-cols-3 xl:mx-0 xl:gap-x-8" }, { children: [_jsx(Plan, { name: "\u4E2A\u4EBA\u7248", price: "\u514D\u8D39", description: "\u63D0\u4F9B\u57FA\u672C\u7684\u6587\u732E\u7BA1\u7406\u529F\u80FD\uFF0C\u5B8C\u7F8E\u9002\u5408\u65E5\u5E38\u5B66\u4E60\u548C\u5DE5\u4F5C\u9700\u6C42\u3002", href: "/shop.html", features: [
                                '文献管理功能',
                                '文献助手插件',
                                '每日200页的免费文献翻译额度',
                                '工作区文件跨设备云同步',
                                '极译AI功能',
                            ] }), _jsx(Plan, { featured: true, name: "\u56E2\u961F\u7248", price: "\u00A524.9", description: "\u4E13\u4E3A\u6EE1\u8DB3\u9AD8\u6548\u56E2\u961F\u534F\u4F5C\u548C\u590D\u6742\u9879\u76EE\u7BA1\u7406\u9700\u6C42\u8BBE\u8BA1\uFF0C\u63D0\u4F9B\u5168\u9762\u7684\u6587\u732E\u5171\u4EAB\u3001\u534F\u4F5C\u5BA1\u9605\u548C\u56E2\u961F\u6C9F\u901A\u529F\u80FD\u3002", href: "/shop.html", features: [
                                '全功能文献管理功能',
                                '定制功能文献助手插件',
                                '每日至多10000页的免费文献翻译额度',
                                '团队工作区文件云同步',
                                '多人协作文档功能',
                                '团队多角色极译AI',
                            ] }), _jsx(Plan, { name: "\u4E13\u4E1A\u7248", price: "\u00A54.9", description: "\u63D0\u4F9B\u5168\u9762\u548C\u9AD8\u7EA7\u7684\u6587\u732E\u7BA1\u7406\u529F\u80FD\uFF0C\u4E3A\u79D1\u7814\u4EBA\u5458\u548C\u4E13\u4E1A\u7528\u6237\u8BBE\u8BA1\u3002", href: "/shop.html", features: [
                                '全面的文献管理功能',
                                '定制功能的文献助手插件',
                                '每日至多2000页的免费文献翻译额度',
                                '个人版云存储与跨设备同步',
                                '私人自定义极译AI',
                            ] })] }))] }) })));
}
