import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Tab } from '@headlessui/react';
import clsx from 'clsx';
import { Container } from './Container';
import backgroundImage from '../images/background-features.jpg';
import screenshotExpenses from '../images/screenshots/translate.png';
import screenshotPayroll from '../images/screenshots/note.png';
import screenshotReporting from '../images/screenshots/addons.png';
import screenshotVatReturns from '../images/screenshots/worktop.png';
const features = [
    {
        title: '团队共享',
        description: "需要与团队共同讨论？需要分享您的阅读过程？极译文献为您提供了团队合作阅读模式，加入您的团队空间，无缝进行重点标注，参与文章讨论。",
        image: screenshotPayroll,
    },
    {
        title: '阅读标注',
        description: "极译文献在您的文献阅读过程中提供了众多辅助工具，您可以使用全文翻译、文章总结等AI工具辅助阅读，同时在您感兴趣的部分添加笔记。",
        image: screenshotExpenses,
    },
    {
        title: '文献整理',
        description: "文章列表过于杂乱？无需担心，极译文献为你提供了文献管理工作台。无论是聚焦搜索，还是文章分类，都能轻松搞定。",
        image: screenshotVatReturns,
    },
    {
        title: '论文收集',
        description: '极译文献提供了配套的文献助手插件，使得在您的日常工作中，会自动搜索您可能感兴趣的文章。您可以选择您需要的文章，连带文献引用，一键加入到您的文献仓库。',
        image: screenshotReporting,
    },
];
export function PrimaryFeatures() {
    let [tabOrientation, setTabOrientation] = useState('horizontal');
    useEffect(() => {
        let lgMediaQuery = window.matchMedia('(min-width: 1024px)');
        function onMediaQueryChange({ matches }) {
            setTabOrientation(matches ? 'vertical' : 'horizontal');
        }
        onMediaQueryChange(lgMediaQuery);
        lgMediaQuery.addEventListener('change', onMediaQueryChange);
        return () => {
            lgMediaQuery.removeEventListener('change', onMediaQueryChange);
        };
    }, []);
    return (_jsxs("section", Object.assign({ id: "features", "aria-label": "Features for running your books", className: "relative overflow-hidden bg-blue-600 pb-28 pt-20 sm:py-32" }, { children: [_jsx("img", { className: "absolute left-1/2 top-1/2 max-w-none translate-x-[-44%] translate-y-[-42%]", src: backgroundImage, alt: "", width: 2245, height: 1636 }), _jsxs(Container, Object.assign({ className: "relative" }, { children: [_jsxs("div", Object.assign({ className: "max-w-2xl md:mx-auto md:text-center xl:max-w-none" }, { children: [_jsx("h2", Object.assign({ className: "font-display text-3xl tracking-tight text-white sm:text-4xl md:text-5xl" }, { children: "\u4F60\u9700\u8981\u7684\u4E00\u5207\u90FD\u5728\u6781\u8BD1\u3002" })), _jsx("p", Object.assign({ className: "mt-6 text-lg tracking-tight text-blue-100" }, { children: "\u65E0\u8BBA\u662F\u8BBA\u6587\u6536\u96C6\u3001\u6587\u732E\u6574\u7406\u3001\u9605\u8BFB\u6807\u6CE8\u8FD8\u662F\u56E2\u961F\u5171\u4EAB\uFF0C\u6781\u8BD1\u6587\u732E\u4E3A\u60A8\u63D0\u4F9B\u4E86\u5B66\u672F\u79D1\u7814\u7684\u4E00\u7AD9\u5F0F\u5168\u6D41\u7A0B\u670D\u52A1\u3002" }))] })), _jsx(Tab.Group, Object.assign({ as: "div", className: "mt-16 grid grid-cols-1 items-center gap-y-2 pt-10 sm:gap-y-6 md:mt-20 lg:grid-cols-12 lg:pt-0", vertical: tabOrientation === 'vertical' }, { children: ({ selectedIndex }) => (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ className: "-mx-4 flex overflow-x-auto pb-4 sm:mx-0 sm:overflow-visible sm:pb-0 lg:col-span-5" }, { children: _jsx(Tab.List, Object.assign({ className: "relative z-10 flex gap-x-4 whitespace-nowrap px-4 sm:mx-auto sm:px-0 lg:mx-0 lg:block lg:gap-x-0 lg:gap-y-1 lg:whitespace-normal" }, { children: features.map((feature, featureIndex) => (_jsxs("div", Object.assign({ className: clsx('group relative rounded-full px-4 py-1 lg:rounded-l-xl lg:rounded-r-none lg:p-6', selectedIndex === featureIndex
                                                ? 'bg-white lg:bg-white/10 lg:ring-1 lg:ring-inset lg:ring-white/10'
                                                : 'hover:bg-white/10 lg:hover:bg-white/5') }, { children: [_jsx("h3", { children: _jsxs(Tab, Object.assign({ className: clsx('font-display text-lg ui-not-focus-visible:outline-none', selectedIndex === featureIndex
                                                            ? 'text-blue-600 lg:text-white'
                                                            : 'text-blue-100 hover:text-white lg:text-white') }, { children: [_jsx("span", { className: "absolute inset-0 rounded-full lg:rounded-l-xl lg:rounded-r-none" }), feature.title] })) }), _jsx("p", Object.assign({ className: clsx('mt-2 hidden text-sm lg:block', selectedIndex === featureIndex
                                                        ? 'text-white'
                                                        : 'text-blue-100 group-hover:text-white') }, { children: feature.description }))] }), feature.title))) })) })), _jsx(Tab.Panels, Object.assign({ className: "lg:col-span-7" }, { children: features.map((feature) => (_jsxs(Tab.Panel, Object.assign({ unmount: false }, { children: [_jsxs("div", Object.assign({ className: "relative sm:px-6 lg:hidden" }, { children: [_jsx("div", { className: "absolute -inset-x-4 bottom-[-4.25rem] top-[-6.5rem] bg-white/10 ring-1 ring-inset ring-white/10 sm:inset-x-0 sm:rounded-t-xl" }), _jsx("p", Object.assign({ className: "relative mx-auto max-w-2xl text-base text-white sm:text-center" }, { children: feature.description }))] })), _jsx("div", Object.assign({ className: "mt-10 w-[45rem] overflow-hidden rounded-xl bg-slate-50 shadow-xl shadow-blue-900/20 sm:w-auto lg:mt-0 lg:w-[67.8125rem]" }, { children: _jsx("img", { className: "w-full", src: feature.image, alt: "", sizes: "(min-width: 1024px) 67.8125rem, (min-width: 640px) 100vw, 45rem" }) }))] }), feature.title))) }))] })) }))] }))] })));
}
