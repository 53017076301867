export const DBConfig = {
    name: "Conversions",
    version: 1,
    objectStoresMeta: [
        {
            store: "conversions",
            storeConfig: { keyPath: "uid", autoIncrement: false },
            storeSchema: [
                { name: "pdfFile", keypath: "pdfFile", options: { unique: false } },
                { name: "state", keypath: "state", options: { unique: false } },
                { name: "md", keypath: "md", options: { unique: false } },
                { name: "translationMd", keypath: "translationMd", options: { unique: false } },
                { name: "pdfId", keypath: "pdfId", options: { unique: false } },
                { name: "translationId", keypath: "translationId", options: { unique: false } },
                { name: "lang", keypath: "lang", options: { unique: false } },
                { name: "submitTime", keypath: "submitTime", options: { unique: false } },
                { name: "updateTime", keypath: "updateTime", options: { unique: false } },
                { name: "hash", keypath: "hash", options: { unique: false } },
                { name: "team", keypath: "team", options: { unique: false } },
                { name: "fileholder", keypath: "fileholder", options: { unique: false } },
                { name: "customTrans", keypath: "customTrans", options: { unique: false } },
                { name: "firstPageImage", keypath: "firstPageImage", options: { unique: false } },
                { name: "citation", keypath: "citation", options: { unique: false } },
                { name: "extra", keypath: "extra", options: { unique: false } },
                { name: "sync", keypath: "sync", options: { unique: false } },
            ],
        },
        {
            store: "fileholders",
            storeConfig: { keyPath: "id", autoIncrement: false },
            storeSchema: [
                { name: "team", keypath: "team", options: { unique: false } },
                { name: "name", keypath: "name", options: { unique: false } },
                { name: "time", keypath: "time", options: { unique: false } },
            ],
        },
        {
            store: "articles",
            storeConfig: { keyPath: "outer_id", autoIncrement: false },
            storeSchema: [
                { name: "name", keypath: "name", options: { unique: false } },
                { name: "group", keypath: "group", options: { unique: false } },
                { name: "update_time", keypath: "update_time", options: { unique: false } },
                { name: "value", keypath: "value", options: { unique: false } },
            ],
        },
        {
            store: "images",
            storeConfig: { keyPath: "id", autoIncrement: false },
            storeSchema: [
                { name: "file", keypath: "file", options: { unique: false } },
                { name: "origin", keypath: "origin", options: { unique: false } },
                { name: "time", keypath: "time", options: { unique: false } },
            ],
        },
    ],
};
// 数据库存储状态
// uid: string; 1,2,3,4,5,0
// pdfFile: File; 1*,2,3,4,5,0
// state: number; 1,2,3,4,5,0
// md: string; 4*,5,0
// translationMd: string; 0*
// pdfId: string; 2*,3,4,5,0
// translationId: string; 5*,0
// lang: string; 0*
// submitTime: number; 1,2,3,4,5,0
// hash: string; 1,2,3,4,5,0
